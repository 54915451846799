.SignedInLinks--item {
  flex: 0 0 auto;
}

.SignedInLinks--Links {
  padding: 0.5rem;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  padding: 0 0.5rem;
  margin: 0.8125rem 0;
  color: var(--mid-grey);
}

@media screen and (min-width: 40em) {
  .SignedInLinks--Links {
    padding: 0 1rem;
    margin: 1.25rem 0;
  }
}

.SignedInLinks--SignOutLink {
  color: var(--mid-blue);
  border-left: 1px solid var(--mid-grey);
  font-weight: 500;
}
