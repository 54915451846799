html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

:root {
  --light-red: rgb(250, 104, 102);
  --ultra-light-blue: rgb(242, 251, 254);
  --mid-blue: rgb(0, 170, 230);
  --black: rgb(0, 0, 0);
  --ultra-dark-grey: rgb(30, 30, 30);
  --mid-grey: rgb(125, 125, 125);
  --ultra-light-grey: rgb(240, 240, 240);
  --white: rgb(255, 255, 255);
}

body {
  font-size: 0.8125rem;
  background-color: var(--white);
  color: var(--ultra-dark-grey);
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  margin-right: 20px;
}

label input,
label select {
  margin-left: 5px;
}

p {
  margin: 0;
}

ul li {
  list-style-type: none;
}

.App-Container {
  margin: 0 auto;
  padding: 0 0.5rem;
  max-width: 1024px;
}
